/* header */

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5%;
  background-color: rgba(30, 38, 49, 0.8) !important;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  z-index: 1000;
  border-bottom: 1px solid rgba(48, 54, 61, 0.5);
}

.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  /* background-color: #fff; */
}
.grey-color {
  color: #868e96;
}

.header li a {
  display: block;
  padding: 20px 20px;
  /* color: black; */
  text-decoration: none;
}

.header li a:hover,
.header .menu-btn:hover {
  /* background-color: #A6E1FA; */
}

.header .logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: 0;
  padding: 0;
}
.header .logo-name {
  color: #8b949e;
  font-size: 1.5rem;
  font-weight: 500;
  font-family: "Google Sans", sans-serif;
  margin-left: 40px;
}

.header .logo-name:hover {
  color: #c9d1d9;
}

/* menu */

.menu {
  font-family: "Google Sans Regular";
}

.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

/* menu icon */

.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 370px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */

@media (min-width: 48em) {
  .header li {
    float: left;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header .menu {
    max-height: none;
  }
  .header .menu-icon {
    display: none;
  }
}
@media (max-width: 960px) and (min-width: 768px) {
  .header {
    font-size: 12px;
  }
}

.header-content {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  text-decoration: none;
  padding-left: 0;
}

.logo-name {
  color: #8b949e;
  font-size: 1.5rem;
  font-weight: 500;
  font-family: "Google Sans", sans-serif;
  transition: color 0.3s ease;
}

.logo-name:hover {
  color: #c9d1d9;
}

.navigation-links {
  display: flex;
  gap: 3rem;
  margin: 0;
  padding: 0;
}

.nav-link {
  text-decoration: none;
  color: #8b949e;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  transition: all 0.3s ease;
  font-size: 1rem;
  font-weight: 500;
}

.nav-link:hover {
  color: #c9d1d9;
  background-color: rgba(110, 64, 201, 0.1);
}

.nav-link.active {
  color: #c9d1d9;
  background-color: rgba(110, 64, 201, 0.15);
}

.menu-button {
  color: #c9d1d9 !important;
}

.drawer-list {
  background-color: #161b22;
  height: 100%;
  width: 250px;
  padding: 2rem 1rem;
}

.drawer-link {
  text-decoration: none;
  color: inherit;
  display: block;
  padding: 0.8rem 1rem;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.drawer-link:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.drawer-link.active {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (max-width: 900px) {
  .header-content {
    padding: 0.5rem 1rem;
  }
}
