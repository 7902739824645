.skills-container {
  padding: 2rem 0;
}

.skills-section-title {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 2.5rem;
  font-weight: 500;
}

.skills-category-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 0 1rem;
}

.skill-category-card {
  padding: 2rem;
  border-radius: 15px !important;
  transition:
    transform 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out;
}

.skill-category-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2) !important;
}

.category-title {
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: 500;
}

.skills-list {
  margin-top: 1.5rem;
}

.skill-item {
  margin-bottom: 0.8rem;
  font-size: 0.95rem;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .skills-section-title {
    font-size: 2rem;
  }

  .skills-category-grid {
    grid-template-columns: 1fr;
    padding: 0 0.5rem;
  }

  .skill-category-card {
    padding: 1.5rem;
  }
}
