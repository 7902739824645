.dev-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 0;
  list-style: none;
  font-size: 2.5rem;
  margin-bottom: 0;
  margin-top: 0;
}

.software-skill-inline {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  font-family: "Google Sans Regular";
}

.software-skill-inline > i {
  color: #868e96;
}

.software-skill-inline > i:hover {
  color: #645beb;
}

.skill-image {
  height: 40px;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 768px) {
  .dev-icons {
    font-size: 2rem;
  }

  .software-skill-inline {
    margin: 8px;
  }

  .skill-image {
    height: 32px;
  }
}
